<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    <div slot="vertical-menu-header" class="d-flex align-items-center h-100">
      <div class="nav_div">
        <div class="nav_logo">
          <img :src="appLogoImage" style="height: 30px" />
        </div>
        <div class="nav_brand">
          <span
            style="
              font-weight: bold;
              font-size: 18px;
              text-align: center;
              padding: 3px 0;
              margin: 0;
            "
            >JVSAKK - Asia</span
          >
        </div>
      </div>
    </div>

    <div slot="footer">
      <p class="mb-0">
        &copy; {{ companyName }} {{ new Date().getFullYear() }}
      </p>
    </div>
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from "../components/Navbar.vue";
import { $themeConfig } from "@themeConfig";
import store from "@/store";

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      appName: $themeConfig.app.appName,
      appLogoImage: $themeConfig.app.appLogoImage,
      companyName: $themeConfig.app.companyName,
    };
  },
  mounted() {
    //console.log("mounted");
    store.dispatch("app/fetchUserAuth").then((response) => {
      //console.log(response.data.user.ability)
      let ability =
        response &&
        response.data &&
        response.data.user &&
        response.data.user.ability;
      if (ability) {
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        this.$ability.update(ability);
      }
    });
  },
};
</script>

<style>
#menu_title {
  font-size: 0.9rem !important;
}
.nav_div {
  display: flex;
}
.nav_brand {
  margin-left: 10px;
  margin-top: 3px;
}
</style>
